import { SET_ENABLE_PRODUCT_DROPDOWN, SET_ENABLE_VARIANT_MANAGEMENT } from "../constants/action-types";

const initialState = {
  enable_product_dropdown: false,
  enable_variant_management: false,
};

const productsSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case SET_ENABLE_PRODUCT_DROPDOWN:
      return { ...state, enable_product_dropdown: action.payload };
    case SET_ENABLE_VARIANT_MANAGEMENT:
      return { ...state, enable_variant_management: action.payload };
    default:
      return state;
  }
};

export default productsSettingsReducer;
