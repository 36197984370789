import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  wrapper: {
    borderRadius: "10px",
    paddingBottom: 60,
  },
  header: {
    padding: "10px 20px",
  },
  toolbar: {
    padding: "10px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  crudButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    padding: "10px 20px",
  },
  cardRoot: {
    width: 245,
    minHeight: 260,
    borderRadius: 8,
    marginRight: 20,
    marginTop: "1rem",
    marginBottom: "2rem",
    position: "relative",
    background: "#f6f6f6",
  },
  cardTitle: {
    fontSize: 14,
  },
  toggleButton: {
    padding: "5px 25px",
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

export default styles;
