import React from "react";
import { useSelector } from "react-redux";
import Product from "./Product";
import Categories from "./Categories";
import Brand from "./Brand";
import Variant from "./Variant";
import Settings from "./Settings";
import ProductSubscription from "./ProductSubscription";
import { TabsGenerator } from "../../../../Common/";
import Languages from "./Languages";
import VariantManagement from "./Variant Management";




const ProductManagement = () => {
  const currentUser = useSelector(state => state.userReducer.current_user);
  const enable_product_dropdown = useSelector(state => state.productsSettingsReducer.enable_product_dropdown);
  const enable_variant_management = useSelector(state => state.productsSettingsReducer.enable_variant_management);
  const tabs = [
    {
      label: "Products",
      value: "product",
      component: <Product />,
    },
    {
      label: "Categories",
      value: "categories",
      component: <Categories />,
    },
    {
      label: "Brands",
      value: "brands_management",
      component: <Brand />,
    },
    ...(enable_variant_management
      ? [
          {
            label: "Variant Management",
            value: "variant_management",
            component: <VariantManagement />,
        },
        ]
      : [
        {
          label: "Variants",
          value: "variant",
          component: <Variant />,
        },
    ]),
    {
      label: "Product Subscription",
      value: "product_subscription",
      component: <ProductSubscription />,
    },
    ...(currentUser.type === "SA" || enable_product_dropdown
      ? [
          {
            label: "Languages",
            value: "languages",
            component: <Languages />,
          },
        ]
      : []),
    {
      label: "Settings",
      value: "settings",
      component: <Settings />,
    },
  ];
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="product" />
    </>
  );

};

export default ProductManagement;
