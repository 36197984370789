import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Card,
  CircularProgress,
} from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { useTheme } from "@material-ui/core/styles";

import Checkbox from "@material-ui/core/Checkbox";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ContentHeader from "../common/ContentHeader";
import CrudDialog from "../common/CrudDialog";
import productImage from "../../../../../../assets/images/product.png";
import styles from "../styles";
import {
  useDevice,
  usePlanogramDetails,
  usePlanogramEdit,
  useProducts,
} from "../hooks";
import "./index.css";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import "./styles.css";
import { AddButton } from "components/Common/Buttons";

const PlanogramDetails = ({ onSave = () => {}, setEdited }) => {
  const { planogramId } = useParams();
  const { details, isLoading, isFetched } = usePlanogramDetails(planogramId);
  const { editProductDetails, isEditing } = usePlanogramEdit(planogramId);
  const [addModal, setAddModal] = useState(false);
  const [localProductDetailsData, setLocalProductDetailsData] = useState([]);
  const theme = useTheme();
  const [selectedPeerDevice, setSelectedPeerDevice] = useState("");
  const [peerDeviceInputValue, setPeerDeviceInputValue] = useState("");
  const [deviceData, setDeviceData] = useState("");
  const [priceChange, setPriceChange] = useState(false);
  const [modulesPlanograms, setModulesPlanograms] = useState([]);
  const [productSorting, setProductSorting] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [emergencyProduct, setEmergencyProduct] = useState(-1);
  const [enableEmergencyProduct, setEnableEmergencyProduct] = useState(false);
  const [selectedProductType, setSelectedProductType] = useState("products");

  const current_user = useSelector((state) => state.userReducer?.current_user);

  const history = useHistory();

  const { device, isDeviceLoading } = useDevice(
    localStorage.getItem("deviceId")
  );

  const { productsSelectData, productsData, fetchProducts } = useProducts({
    initialFetch: false,
    rental_products: details?.rental_products,
  });

  const { enqueueSnackbar } = useSnackbar();
  const classes = styles();

  const addProductsFields = [
    {
      key: "product_ids",
      label: "Product",
      options: _.uniqBy(
        [
          ...(productsSelectData || []),
          ..._.map(details?.product_details, (x) => ({
            value: x.id,
            label: `${x.product_sku} ${x.product_name} ${
              details.rental_products?.indexOf(x.id) > -1 ? "(Rental)" : ""
            }`,
          })),
        ],
        "value"
      ),
      visible: true,
      show: true,
      filterSelectedOptions: true,
      type: "multiAutoComplete",
      multiple: true,
      required: true,
    },
  ];

  useEffect(() => {
    const currencySymbol = localStorage.getItem("currency_symbol") || "$";
    setCurrencySymbol(currencySymbol);
  }, []);

  useEffect(() => {
    if (current_user.type === "SA") {
      if (details?.operator) {
        fetchProducts(details?.operator);
      } else if (isFetched) {
        enqueueSnackbar("Please allocate an operator to this device");
      }
    } else {
      fetchProducts();
    }
  }, [details?.operator]);

  useEffect(() => {
    /**
     * if serial number is not matching with the location.state.deviceID
     *   then
     *     - match serial number of selected peer device with peer device array
     *     - update the planogram view data with peer device's planogram
     *
     *   else
     *     update the planogram as we normally do
     */

    // if (location.state?.serial_number === selectedPeerDevice?.value) {
    const products = details?.product_details.map((product) => {
      let productDetails = productsData.find((x) => x.id === product.id);
      return {
        ...product,
        base_product: productDetails?.base_product,
        has_variants: productDetails?.has_variants,
        variant_type: productDetails?.variant_type,
      };
    });
    setLocalProductDetailsData(products);
    // } else {
    //   setLocalProductDetailsData(
    //     _.uniqBy(
    //       details?.peer_devices?.find(
    //         (x) => x.peer_serial_number === selectedPeerDevice?.value
    //       )?.data?.product_details || [],
    //       "id"
    //     )
    //   );
    // }
  }, [selectedPeerDevice]);

  useEffect(() => {
    if (device?.results?.length) {
      setDeviceData([
        {
          label: device?.results?.[0]?.serial_number,
          value: device?.results?.[0]?.serial_number,
        },
        ..._.map(device?.results?.[0]?.peer_device_ids, (x) => ({
          label: x,
          value: x,
        })),
      ]);

      setSelectedPeerDevice({
        label: device?.results?.[0]?.serial_number || "",
        value: device?.results?.[0]?.serial_number || "",
      });
    }
  }, [device]);

  useEffect(() => {
    setLocalProductDetailsData(
      _.uniqBy(
        details?.product_details.map((product) => {
          let productDetails = productsData.find((x) => x.id === product.id);
          return {
            ...product,
            base_product: productDetails?.base_product,
            has_variants: productDetails?.has_variants,
            variant_type: productDetails?.variant_type,
          };
        }),
        "id"
      )
    );
    setEmergencyProduct(
      details?.product_details
        ? _.find(details?.product_details, { is_emergency_product: true })?.id
        : -1
    );
  }, [details]);

  const getModulesPlanogram = async () => {
    const { data: planogramData } = await window.axiosIns.get("/planogram", {
      params: {
        cabinet_id__vm_id__oro_id: localStorage.getItem("deviceId"),
        peer_device: true,
      },
    });
    setModulesPlanograms(planogramData?.results || []);
  };
  const getProductSettings = async () => {
    const data = await window.axiosIns.get("/products_settings");
    setProductSorting(
      data?.data?.results[0]?.enable_planogram_product_sorting || false
    );
    setEnableEmergencyProduct(
      data?.data?.results[0]?.enable_emergency_product_flag || false
    );
  };

  useEffect(() => {
    getModulesPlanogram();
    getProductSettings();
  }, []);

  const handleDelete = (id) => {
    setLocalProductDetailsData(
      _.uniqBy(
        _.filter(localProductDetailsData, (x) => x.id !== id),
        "id"
      )
    );
    if (id === emergencyProduct) {
      setEmergencyProduct(-1);
    }
    setEdited(true);
  };

  const handleChange = (id, value) => {
    const product_details = _.map(localProductDetailsData, (x) => {
      if (id === x.id) {
        return { ...x, price: value };
      } else {
        return { ...x };
      }
    });

    setLocalProductDetailsData(_.uniqBy(product_details, "id"));
  };

  const handleBlur = (id, value) => {
    const product_details = _.map(localProductDetailsData, (x) => {
      if (id === x.id) {
        return { ...x, price: value?.toFixed(2) };
      } else {
        return { ...x };
      }
    });

    setLocalProductDetailsData(_.uniqBy(product_details, "id"));
  };

  const handleProductSubmit = async (values) => {
    const product_details = _.filter(
      [...productsData, ...localProductDetailsData],
      (x) => values.product_ids.includes(x.id)
    );
    const filtered_product_details = _.reverse(
      _.uniqBy(_.reverse(product_details), "id")
    ).sort((a, b) => {
      if (a.base_product == null && b.base_product != null) return -1;
      if (a.base_product != null && b.base_product == null) return 1;
      return 0;
    });
    setLocalProductDetailsData(filtered_product_details);
    setAddModal(false);
    setEdited(true);
  };

  const onSavePressed = async () => {
    const productDetailsData = localProductDetailsData.map((item, index) => {
      const updatedItem = {
        ...item,
        order: productSorting
          ? item.base_product === null
            ? index + 1
            : null
          : null,
        is_emergency_product: emergencyProduct === item.id,
      };
      return updatedItem;
    });

    if (device.results[0].is_m_series) {
      const promises = modulesPlanograms.map((item) => {
        let planogram_details;
        planogram_details = _.map(item?.details, (x) => {
          if (productDetailsData.find((y) => y.id == x.product_id)) {
            return { ...x, stock: 0 };
          } else {
            return {
              ...x,
              price: "",
              product_id: null,
              product_name: "",
              stock: 0,
            };
          }
        });
        _.forEach(item?.peer_devices, (x) => {
          if (x?.data?.product_details) {
            x.data.product_details = productDetailsData;
          }
        });
        const editData = {
          ...item,
          product_details: productDetailsData,
          planogram_details,
        };
        return editProductDetails(editData);
      });

      Promise.all(promises).then(() => {
        enqueueSnackbar("Products updated successfully");
        setEdited(false);
        onSave();
      });
    } else {
      let planogram_details;
      /** Flush out the products which are not there in the product details */
      planogram_details = _.map(details?.details, (x) => {
        if (productDetailsData.find((y) => y.id == x.product_id)) {
          return { ...x, stock: 0 };
        } else {
          return {
            ...x,
            price: "",
            product_id: null,
            product_name: "",
            stock: 0,
          };
        }
      });

      _.forEach(details?.peer_devices, (x) => {
        if (x?.data?.product_details) {
          x.data.product_details = productDetailsData;
        }
      });

      const editData = {
        ...details,
        product_details: productDetailsData,
        planogram_details,
      };

      await editProductDetails(editData);
      enqueueSnackbar("Products updated successfully");
      setEdited(false);
      onSave();
    }
  };

  const handleOrderChange = (updatedList) => {
    const product_details = _.map(updatedList, (x, index) => {
      return { ...x, order: index + 1 };
    });
    setLocalProductDetailsData(product_details);
  };

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...localProductDetailsData];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    handleOrderChange(updatedList);
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader title="" />
      <Card style={{ margin: "0px 20px 20px 20px" }} variant="outlined">
        <Grid
          style={{
            padding: "10px 20px",
            display: "flex",
            alignItems: "center",
          }}
          container
        >
          <Grid item xs={4}>
            <Grid
              style={{ display: "inline-flex", alignItems: "center" }}
              container
            >
              <Grid xs={3}>
                <Typography>
                  <b>VM Serial:</b>
                </Typography>
              </Grid>
              <Grid xs={9}>
                <Typography>{details?.serial_number || ""}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container>
              <Grid xs={4}>
                <Typography>
                  <b>Network Status:</b>
                </Typography>
              </Grid>
              <Grid xs={8}>
                <Typography>
                  {isLoading
                    ? "Loading..."
                    : details?.status
                    ? "Online"
                    : "Offline"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container>
              <Grid xs={4}>
                <Typography>
                  <b>VM Sync:</b>
                </Typography>
              </Grid>
              <Grid xs={8}>
                <Typography>Synced</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <div className={classes.toolbar}>
        <div className={classes.crudButtons} style={{ width: "100%" }}>
          <div>
            <AddButton
              className="mr-3"
              label="Add Product"
              variant="contained"
              onClick={() => setAddModal(true)}
              color="primary"
              disabled={isEditing}
              size="medium"
            />
            <Button
              className="mr-3"
              variant="contained"
              onClick={onSavePressed}
              color="primary"
              disabled={isEditing}
              size="medium"
            >
              Save
            </Button>
            <Button
              className="mr-3 text-red"
              onClick={() => {
                localStorage.removeItem("deviceId");
                localStorage.removeItem("serial_number");
                localStorage.removeItem("module_number");
                localStorage.removeItem("vm_device");
                history.push("/planogram");
              }}
              variant="outlined"
              size="medium"
            >
              Close
            </Button>
          </div>
          <ToggleButtonGroup
            value={selectedProductType}
            exclusive
            onChange={(_, val) => setSelectedProductType(val)}
            aria-label="Product Type"
          >
            <ToggleButton
              value="products"
              aria-label="Products"
              className={classes.toggleButton}
            >
              Products
            </ToggleButton>
            <ToggleButton
              value="variants"
              aria-label="Variants"
              className={classes.toggleButton}
            >
              Variants
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>

      {isLoading || isDeviceLoading || isEditing ? (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                className="list-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {localProductDetailsData &&
                localProductDetailsData.length <= 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 20,
                    }}
                  >
                    No product added.
                  </div>
                ) : (
                  localProductDetailsData.map((__, index) =>
                    selectedProductType === "products" ? (
                      __.base_product === null ? (
                        <Draggable
                          key={"" + __.id}
                          draggableId={"" + __.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="item-container"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <DragIndicatorIcon color="primary" />
                                  <img
                                    style={{
                                      height: 50,
                                      width: 50,
                                      marginLeft: 10,
                                    }}
                                    alt="product_image"
                                    src={__?.product_picture || productImage}
                                  />
                                  <span style={{ margin: 10, marginLeft: 20 }}>
                                    {`${
                                      __?.product_sku
                                        ? __?.product_sku + " - "
                                        : ""
                                    }${__?.product_name ?? "---"}` || "---"}
                                  </span>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  {productSorting && (
                                    <div style={{ marginRight: 20 }}>
                                      <Typography variant="body1">
                                        {`Display Order : ${index + 1}`}
                                      </Typography>
                                    </div>
                                  )}
                                  {enableEmergencyProduct ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginRight: 20,
                                      }}
                                    >
                                      <Checkbox
                                        color="primary"
                                        value={emergencyProduct === __.id}
                                        checked={emergencyProduct === __.id}
                                        onChange={(e) => {
                                          e.target.value === "false"
                                            ? setEmergencyProduct(__.id)
                                            : setEmergencyProduct(-1);
                                        }}
                                      />
                                      <Typography variant="body1">
                                        Emergency Product
                                      </Typography>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <Typography variant="body1">
                                    Price ({currencySymbol}) :
                                  </Typography>
                                  <input
                                    value={__?.price}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.length > 1 &&
                                        e.target.value[0] === "0"
                                      ) {
                                        if (
                                          !isNaN(Number(e.target.value)) &&
                                          Number(e.target.value) >= 0
                                        ) {
                                          handleChange(
                                            __?.id,
                                            e.target.value.slice(1)
                                          );
                                        }
                                      } else if (
                                        !isNaN(Number(e.target.value)) &&
                                        Number(e.target.value) >= 0
                                      ) {
                                        if (
                                          e.target.value.indexOf(".") !== -1
                                        ) {
                                          if (
                                            e.target.value.split(".")[1]
                                              .length <= 2
                                          )
                                            handleChange(
                                              __?.id,
                                              e.target.value
                                            );
                                        } else {
                                          handleChange(__?.id, e.target.value);
                                        }
                                      } else if (e.target.value === ".")
                                        handleChange(__?.id, e.target.value);
                                    }}
                                    onKeyDown={() => setPriceChange(true)}
                                    style={{
                                      width: 120,
                                      height: 30,
                                      marginLeft: 10,
                                    }}
                                    type="text"
                                    onBlur={(e) => {
                                      if (e.target.value === "") {
                                        handleBlur(__?.id, 0);
                                      } else {
                                        handleBlur(
                                          __?.id,
                                          Number(e.target.value)
                                        );
                                      }
                                    }}
                                  />
                                  <Button
                                    size="small"
                                    onClick={() => handleDelete(__?.id)}
                                    style={{
                                      fontSize: "0.8rem",
                                      color: "red",
                                      margin: 10,
                                      marginLeft: 20,
                                      padding: 8,
                                    }}
                                    color="primary"
                                    variant="outlined"
                                  >
                                    Remove
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ) : null
                    ) : __.base_product !== null ? (
                      <Draggable
                        key={"" + __.id}
                        draggableId={"" + __.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="item-container"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <DragIndicatorIcon color="primary" />
                                <img
                                  style={{
                                    height: 50,
                                    width: 50,
                                    marginLeft: 10,
                                  }}
                                  alt="product_image"
                                  src={__?.product_picture || productImage}
                                />
                                <span style={{ margin: 10, marginLeft: 20 }}>
                                  {`${
                                    __?.product_sku
                                      ? __?.product_sku + " - "
                                      : ""
                                  }${__?.product_name ?? "---"}` || "---"}
                                </span>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                {enableEmergencyProduct ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      marginRight: 20,
                                    }}
                                  >
                                    <Checkbox
                                      color="primary"
                                      value={emergencyProduct === __.id}
                                      checked={emergencyProduct === __.id}
                                      onChange={(e) => {
                                        e.target.value === "false"
                                          ? setEmergencyProduct(__.id)
                                          : setEmergencyProduct(-1);
                                      }}
                                    />
                                    <Typography variant="body1">
                                      Emergency Product
                                    </Typography>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <Typography variant="body1">
                                  Price ({currencySymbol}) :
                                </Typography>
                                <input
                                  value={__?.price}
                                  onChange={(e) => {
                                    if (
                                      e.target.value.length > 1 &&
                                      e.target.value[0] === "0"
                                    ) {
                                      if (
                                        !isNaN(Number(e.target.value)) &&
                                        Number(e.target.value) >= 0
                                      ) {
                                        handleChange(
                                          __?.id,
                                          e.target.value.slice(1)
                                        );
                                      }
                                    } else if (
                                      !isNaN(Number(e.target.value)) &&
                                      Number(e.target.value) >= 0
                                    ) {
                                      if (e.target.value.indexOf(".") !== -1) {
                                        if (
                                          e.target.value.split(".")[1].length <=
                                          2
                                        )
                                          handleChange(__?.id, e.target.value);
                                      } else {
                                        handleChange(__?.id, e.target.value);
                                      }
                                    } else if (e.target.value === ".")
                                      handleChange(__?.id, e.target.value);
                                  }}
                                  onKeyDown={() => setPriceChange(true)}
                                  style={{
                                    width: 120,
                                    height: 30,
                                    marginLeft: 10,
                                  }}
                                  type="text"
                                  onBlur={(e) => {
                                    if (e.target.value === "") {
                                      handleBlur(__?.id, 0);
                                    } else {
                                      handleBlur(
                                        __?.id,
                                        Number(e.target.value)
                                      );
                                    }
                                  }}
                                />
                                <Button
                                  size="small"
                                  onClick={() => handleDelete(__?.id)}
                                  style={{
                                    fontSize: "0.8rem",
                                    color: "red",
                                    margin: 10,
                                    marginLeft: 20,
                                    padding: 8,
                                  }}
                                  color="primary"
                                  variant="outlined"
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ) : null
                  )
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      <CrudDialog
        title="Add Products"
        okText="Add"
        values={{ product_ids: _.map(localProductDetailsData, (x) => x.id) }}
        fields={addProductsFields}
        description="Please fill in the details below."
        onSubmit={handleProductSubmit}
        open={addModal}
        onClose={() => {
          setAddModal(false);
        }}
      />
    </div>
  );
};

export default PlanogramDetails;
