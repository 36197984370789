import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import { AddButton, EditButton, DeleteButton, ExportButton, ImportButton } from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import { useSnackbar } from "notistack";
import moment from 'moment';
import * as XLSX from 'xlsx';
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import { currencyFormatter, getTimeZoneDifference, convertUtcToLocal, dateFormatter, taxFormatter } from "utils/helpers";
import InfoModal from "components/Common/AlertDialog/info_dialogue";

const filterLabels = {
  product_sku: "product_sku",
  variant_name: "product_name",
  product_name: 'parent_product',
  operator: "operator__business_name",
  product_type: "product_type",
  status: "status",
};

const deviceTypeFields = ["product_sku", "variant_name", "product_name", "operator", "product_type", "status"];




const Variant = (props) => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [deviceTypeList, setDeviceList] = useState([]);
  const [products, setProducts] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [customerList, setCustomersList] = useState([]);
  const [isMutating, setIsMutating] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});
  const [subCategory, setSubCategory] = useState([])
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [crudLoader, setCrudLoader] = useState(false);

  const current_user = useSelector((state) => state.userReducer.current_user);
  const { enqueueSnackbar } = useSnackbar();
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
  
    return <Tooltip arrow placement="top" classes={classes} {...props} />;
  }
  const fields = [
    { key: "id", columnName: "ID", label: "ID", type: "text", visible: true },
    {
      key: "product_sku",
      columnName: "Product SKU",
      label: "Product SKU",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value || "---"
    },
    {
      key: "product_name",
      columnName: "Variant Name",
      label: "Variant Name",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---"
    },

    {
      key: "operator_name",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---"
    },
    // {
    //   key: "category_name",
    //   columnName: "Product Category",
    //   label: "Product Category",
    //   type: "text",
    //   visible: true,
    // },
    {
      key: "product_picture",
      columnName: "Picture",
      width: 100,
      label: "Product Picture",
      type: "file",
      form: true,
      disableSorting: true,
      visible: true,
      render: (_, values) =>
        values.product_picture && (
          <div
            style={{
              height: 64,
              width: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.product_picture})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "barcode_of_product",
      columnName: subCategory && subCategory['change_barcode_to_desc_img'] ? "Description Image" : "BarCode",
      width: 100,
      label: subCategory && subCategory['change_barcode_to_desc_img'] ? "Description Image" : "BarCode",
      type: "file",
      form: true,
      disableSorting: true,
      visible: ( subCategory && subCategory['enable_barcode'] ) ?? true,
      render: (_, values) =>
        values.barcode_of_product && (
          <div
            style={{
              height: 64,
              width: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.barcode_of_product})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "more_info_img",
      columnName: "More Info",
      width: 100,
      label: "More Info",
      type: "file",
      form: true,
      visible: subCategory && subCategory['enable_info_img'] || false,
      disableSorting: true,
      render: (_, values) =>
        values.more_info_img && (
          <div
            style={{
              height: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.more_info_img})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "ingredients_img",
      columnName: "Ingredients",
      width: 100,
      label: "Ingredients",
      type: "file",
      form: true,
      visible: subCategory && subCategory['enable_ingredient_img'] || false,
      disableSorting: true,
      render: (_, values) =>
        values.ingredients_img && (
          <div
            style={{
              height: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.ingredients_img})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "parent_product_name",
      label: "Product",
      columnName: "Product",
      type: "autocomplete",
      required: true,
      show: true,
      freeSolo: false,
      options: products.length
        ? [...products.map((x) => ({ label: x.product_name, value: x.id }))]
        : [{ label: "", value: null }],
      visible: true,
      render: (value) => value ?? "---"
      // render: (_, values) => <p>{values.product_name }</p>
    },

    {
      key: "color",
      columnName: "Color",
      visible: true,
      label: "Color",
      type: "color",
      render: (value) => value || "---"
      // render: (_, values) =>
      //     values.color && (
      //       <BootstrapTooltip title={values.color}>
      //         <div
      //           style={{
      //             width: 40,
      //             height: 40,
      //             borderRadius: 4,
      //             backgroundColor: values.color,
      //           }}
      //         ></div>
      //       </BootstrapTooltip>
      //     ),
    },
    {
      key: "size",
      columnName: "Size",
      visible: true,
      label: "Size",
      type: "text",
      render: (value) => value || "---"
    },
    {
      key: "flavor",
      columnName: "Flavor",
      form: true,
      visible: true,
      label: "Flavor",
      type: "text",
      render: (_, values) => (values.flavor !== "undefined" && values.flavor !== "") ? values.flavor : "---"

    },
    {
      key: "price",
      columnName: "Price ($)",
      visible: true,
      label: "Price ($)",
      type: "text",
      render: (value) => currencyFormatter(value) || "---"
    },

    {
      key: "cost",
      columnName: "Cost($)",
      label: "Cost($)",
      type: "text",
      visible: true,
      render: (value) => currencyFormatter(value) || "---"
    },
    {
      key: "product_tax",
      columnName: "Tax (%)",
      label: "Tax (%)",
      type: "text",
      visible: (subCategory && subCategory['enable_taxes_per_product']) || false,
      render: (value) => taxFormatter(value) ?? "0.0000",
    },
    {
      key: "weight",
      columnName: "Weight in grams",
      label: "Weight (in grams)",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---",
      validations: [
        {
          type: "custom",
          value: (fieldValue) => weightValidation(fieldValue),
          message: "Enter a valid number, with upto 3 decimal places",
        },
      ],
    },
    {
      key: "product_type",
      columnName: "Product Type",
      visible: true,
      label: "Product Type",
      type: "text",
      render: (value) => value || "---"
      // value: "#000000",

    },
    {
      key: "concentration",
      columnName: "Concentration",
      form: true,
      visible: true,
      label: "Concentration",
      type: "text",
      render: (value) => value || "---"
    },
    {
      key: "status",
      columnName: "Status",
      label: "Status",
      type: "text",
      visible: true,
      render: (value) => value || "---"
    },
    {
      key: "age_restriction",
      columnName: "Age Restriction",
      label: "Age Restriction",
      type: "text",
      visible: true,
      render: (value) => value === '0' ? "Not Required" : value ?? "---",
    },
    {
      key: "package_type",
      columnName: "Package",
      label: "Package",
      type: "text",
      visible: true,
    },
    {
      key: "shelf_life",
      columnName: "Shelf life(days)",
      label: "Shelf life(days)",
      type: "text",
      visible: true,
      render: (value) => (value === null || value === undefined) ? "---" : value
    },
    // {
    //   key: "heating_time",
    //   columnName: "Heating time",
    //   label: "Heating time",
    //   type: "text",
    //   visible: true,
    //   render: (value) => (value === null || value === undefined) ? "---" : value
    // },
    {
      key: "product_description",
      columnName: "Subtitle",
      label: "Subtitle",
      type: "text",
      visible: true,
      render: (value) => (
        <Tooltip title={value} aria-label="description">
          <span>
            {value ? `${value.substring(0, 30)}...` : "---"}
          </span>
        </Tooltip>
      )
    },
    {
      key: "long_description",
      columnName: "Description",
      label: "Description",
      type: "textarea",
      visible: true,
      render: (value) => (
        <Tooltip title={value} aria-label="description">
          <span>
            {value ? 
                value.length > 29 ? `${value.substring(0, 30)}...` : value
                : "---"}
          </span>
        </Tooltip>
      )
    },
    {
      key: "created_at",
      columnName: "Creation Time",
      label: "Creation Time",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true),
    },
    {
      key: "updated_at",
      columnName: "Updated time",
      label: "Updated time",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true),
    },
  ];
  const costValidation = (value) => {
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      Number(value) <= 100000 &&
      (value?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 2 : true)
    ) {
      return true;
    }
  };

  const taxValidation = (value) => {
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      Number(value) <= 100 &&
      ((''+value)?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 4 : true)
    ) {
      return true;
    }
  };

  const weightValidation = (value) => {
    if(!value) return true;
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      (value?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 3 : true)
    ) {
      return true;
    }
  };
  
  const getObjects = (position = 1) => {
    if(position === 4){

      let images_obj = []

      if(subCategory && subCategory['enable_barcode']){
        images_obj.push({
          key: "barcode_of_product",
          columnName: subCategory && subCategory['change_barcode_to_desc_img'] ? "Description Image" : "BarCode",
          width: 100,
          label: subCategory && subCategory['change_barcode_to_desc_img'] ? "Description Image" : "BarCode",
          type: "file",
          inputProps: {
            tabIndex: "19",
          },
          form: true,
          visible: true,
          hint: "Suggested file types: png, jpg, jpeg, gif",
          render: (_, values) =>
            values.barcode_of_product && (
              <img
                style={{ width: 84, height: 64 }}
                src={values.barcode_of_product}
                alt="BarCode"
              />
            ),
        })
      }

      if(subCategory && subCategory['enable_info_img']){
        images_obj.push({
          key: "more_info_img",
          columnName: "More Info Image",
          width: 100,
          label: "More Info",
          type: "file",
          inputProps: {
            tabIndex: "20",
          },
          form: true,
          visible: true,
          hint: "Suggested file types: png, jpg, jpeg, gif",
          render: (_, values) =>
            values.more_info_img && (
              <img
                style={{ width: 84, height: 64 }}
                src={values.more_info_img}
                alt="More Info"
              />
            ),
        })
      }
      
      if(subCategory && !( subCategory['enable_info_img'] === subCategory['enable_barcode'] ) && subCategory['enable_ingredient_img']){
        images_obj.push({
                key: "ingredients_img",
                columnName: "Ingredients",
                width: 100,
                label: "Ingredients",
                type: "file",
                inputProps: {
                  tabIndex: "21",
                },
                form: true,
                visible: true,
                hint: "Suggested file types: png, jpg, jpeg, gif",
                render: (_, values) =>
                  values.ingredients_img && (
                    <img
                      style={{ width: 84, height: 64 }}
                      src={values.ingredients_img}
                      alt="Ingredients"
                    />
                  ),
              })
      }
      
      return images_obj
    }
    else{
      return {};
    }

  }

  const addfields = [
    [{
      key: "product_name",
      columnName: "Variant Name",
      label: "Variant Name",
      type: "text",
      visible: true,
      required: true,
    },
    {
      key: "product_sku",
      columnName: "Product SKU",
      label: "Product SKU",
      type: "text",
      visible: true,
      render: (value) => value || "---"
    },

    ],
    [{
      key: "product_id",
      label: "Product",
      columnName: "Product",
      type: "autocomplete",
      required: true,
      show: true,
      freeSolo: false,
      options: products.length
        ? [...products.map((x) => ({ label: x.product_name, value: x.id }))]
        : [{ label: "", value: null }],
      required: true,
      visible: true,
    },
    {
      key: "weight",
      columnName: "Weight in grams",
      label: "Weight (in grams)",
      type: "text",
      visible: true,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => weightValidation(fieldValue),
          message: "Enter a valid number, with upto 3 decimal places",
        },
      ],
      render: (value) => value ?? "---"
    },],
    [{
      key: "price",
      columnName: "Price ($)",
      visible: true,
      label: "Price ($)",
      type: "text",
      required: true,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => costValidation(fieldValue),
          message: "Enter Price between 0 to 100000, only two digits are allowed after decimal",
        },
      ],
    },

    {
      key: "cost",
      columnName: "Cost($)",
      label: "Cost($)",
      type: "text",
      visible: true,
      required: true,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => costValidation(fieldValue),
          message: "Enter Cost price between 0 to 100000, only two digits are allowed after decimal",
        },
      ],
    }],
    [
    {
      key: "package_type",
      columnName: "Package",
      label: "Package",
      type: "text",
      visible: true,
      // required: true,
    },
      {
        key: "shelf_life",
        columnName: "Shelf life(days)",
        label: "Shelf life(days)",
        type: "number",
        visible: true,
        // required: true,
      },
    ],
    [
      // {
      //   key: "heating_time",
      //   columnName: "Heating time",
      //   label: "Heating time",
      //   type: "number",
      //   visible: true,
      //   // required: true,
      // },
      {
        key: "color",
        columnName: "Color",
        visible: true,
        label: "Color",
        type: "text",
        // value: "#000000",
      },
    ],
    [
      {
        key: "flavor",
        columnName: "Flavor",
        form: true,
        visible: true,
        label: "Flavor",
        type: "text",
      },
      {
      key: "size",
      columnName: "Size",
      visible: true,
      label: "Size",
      type: "text",
    },
    ],
    [
      {
        key: "status",
        columnName: "Status",
        label: "Status",
        required: true,
        visible: true,
        show: true,
        freeSolo: false,
        type: "autocomplete",
        options: [
          { label: "Enable", value: "Enable" },
          { label: "Disable", value: "Disable" },
        ],
        unclosable: true,
        defaultValue: { label: "Enable", value: "Enable" }
      },
      {
        key: "product_type",
        columnName: "Product Type",
        visible: true,
        label: "Product Type",
        type: "text",
      },
    ],
    [
      {
        key: "concentration",
        columnName: "Concentration",
        form: true,
        visible: true,
        label: "Concentration",
        type: "text",
      },
      {
        key: "age_restriction",
        columnName: "Age Restriction",
        label: "Age Restriction",
        required: true,
        visible: true,
        freeSolo: false,
        inputProps: {
          tabIndex: "10",
        },
        show: true,
        disabled: JSON.parse(localStorage.getItem("scanner_state")) ? false : true,
        type: "autocomplete",
        options: [
          { label: "Not Required", value: '0' },
          { label: "18", value: 18 },
          { label: "21", value: 21 },
        ],
        defaultValue: { label: "Not Required", value: '0' },
      },
    ],
    {
      key: "operator",
      label: "Operator",
      columnName: "Operator",
      type: "autocomplete",
      show: current_user.type === "FO" || current_user.type === "OP" || current_user.type === "DU" ? false : true,
      freeSolo: false,
      options: customerList.map((value) => ({
        label: `${value.business_name} || (${value.customer_type})`,
        value: value.id,
      })),
    },
    [ 
    {
      key: "product_description",
      columnName: "Subtitle",
      label: "Subtitle",
      type: "text",
      visible: true,
      validations: [
        {
          type: "maxLength",
          value: 100,
          message: "Length should be less then 100 Characters",
        },
      ],
    },
    {
      key: "product_tax",
      columnName: "Tax (%)",
      label: "Tax (%)",
      type: "text",
      value: "0.0000",
      inputProps: {
        tabIndex: "10",
      },
      visible: (subCategory && subCategory['enable_taxes_per_product']) || false,
      required: false,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => (subCategory && subCategory['enable_taxes_per_product']) ? taxValidation(fieldValue) : true,
          message: "Enter tax between 0 to 100, only four digits are allowed after decimal",
        },
      ],
    }],
    {
      key: "long_description",
      columnName: "Description",
      label: "Description",
      type: "text",
      multiline: true,
      visible: true,
      render: (value) => value || "---"
    },
    [{
      key: "product_picture",
      columnName: "Picture",
      width: 100,
      label: "Product Picture",
      type: "file",
      form: true,
      visible: true,
      hint: "Suggested file types: png, jpg, jpeg, gif",
      render: (_, values) =>
        values.product_picture && (
          <img
            style={{ width: 84, height: 64 }}
            src={values.product_picture}
            alt="Product Image"
          />
        ),
    }, {
      key: "images",
      columnName: "Additional Images",
      width: 100,
      label: "Additional Images",
      type: "multiplefile",
      form: true,
      visible: true,
      hint: "Suggested file types: png, jpg, jpeg, gif",
      render: (_, values) => values.images && (
        <img
          style={{ width: 84, height: 64 }}
          src={values.images}
          alt="Additional Images"
        />
      ),
    },
  ],
    getObjects(4),
    [
      subCategory && (subCategory['enable_info_img'] === subCategory['enable_barcode'] ) && subCategory['enable_ingredient_img'] ? {
        key: "ingredients_img",
        columnName: "Ingredients",
        width: 100,
        label: "Ingredients",
        type: "file",
        inputProps: {
          tabIndex: "21",
        },
        form: true,
        visible: true,
        hint: "Suggested file types: png, jpg, jpeg, gif",
        render: (_, values) =>
          values.ingredients_img && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.ingredients_img}
              alt="Ingredients"
            />
          ),
      }: {}
    ],
    [
      subCategory && subCategory['enable_recommend_product'] === true &&
      {
        key: "is_recommended",
        value: false,
        label: "Recommended Product",
        type: "checkbox",
        form: true,
        visible: true
      }
    ]
  ];

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    // setDeviceList(_.filter(data.results, x => x.variant_type !== "default"));
    setDeviceList(data.results);

    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getDeviceType = async (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      state: 'all',
      page: customPage + 1,
      variant: 'variants',
    };
    setup();
    const { data } = await window.axiosIns("products", { params });
    handleRes(data);
  };

  const getProducts = async () => {
    try {
      const { data } = await window.axiosIns('products', {
        params: {
          all: true,
          state: 'all',
          ordering: "product_name",
          variant_type: "default",
        }
      });
      setProducts(data?.results);

      // setProducts(data.results.filter(x => x.variant_type === "default").map(x => ({
      //   value: x.id,
      //   label: x.product_name,
      // })));

    } catch (err) {
      console.log(err);
    }
  };

  const fetchCompanyList = async () => {
    try {
      const { data } = await window.axiosIns.get("company", { params: { all: true, ordering: "business_name" } });
      setCustomersList((data.data || {}).results || []);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getProductSettings = async () => {
    window
      .axiosIns("/products_settings")
      .then(({ data = {} }) => {
        setSubCategory(data.results?.[0])
      })
      .catch((err) => {
        console.log(err)
      });
  };

  useEffect(() => {
    getDeviceType();
    fetchCompanyList();
    getProductSettings();
    getProducts();
  }, []);

  const handleAdd = ({
    product_name,
    product_id,
    color,
    size,
    flavor,
    price,
    cost,
    product_description,
    product_picture,
    package_type,
    shelf_life,
    heating_time,
    status,
    barcode_of_product,
    ingredients_img,
    more_info_img,
    product_type,
    concentration,
    product_sku,
    long_description,
    images,
    weight,
    operator,
    age_restriction,
    is_recommended,
    product_tax
  }) => {
    const fd = new FormData();
    if (images && images[0].type && images.length) {
      for (let i = 0; i < images.length; i++) {
        fd.append(`img${i + 1}`, images[i])
      }
    }
    product_picture && product_picture.type && fd.append("product_picture", product_picture);
    barcode_of_product && barcode_of_product.type && fd.append("barcode_of_product", barcode_of_product);
    more_info_img && more_info_img.type && fd.append("more_info_img", more_info_img);
    ingredients_img && ingredients_img.type && fd.append("ingredients_img", ingredients_img);
    fd.append("product_name", product_name);
    fd.append("long_description", long_description ?? "");
    fd.append("product_sku", product_sku ?? "");
    fd.append("product_id", product_id);
    fd.append("package_type", package_type ?? "");
    fd.append("shelf_life", shelf_life ?? "");
    fd.append("heating_time", heating_time ?? "");
    fd.append("status", status ?? "Enable");
    fd.append("color", color ?? "");
    fd.append("size", size ?? "");
    fd.append("flavor", flavor ?? "");
    fd.append("price", parseFloat(price).toFixed(2));
    fd.append("cost", parseFloat(cost).toFixed(2));
    fd.append("product_type ", product_type ?? "");
    fd.append("concentration ", concentration ?? "");
    fd.append("product_description", product_description ?? "");
    fd.append("weight", weight ?? "");
    fd.append("variant_type", "variant");
    fd.append("operator", operator ?? "");
    fd.append("age_restriction", age_restriction ?? '0');
    fd.append("is_recommended", is_recommended ?? false);
    fd.append("product_tax", product_tax ?? '0.0000');
    fd.append("no_sale_days", "[]");

    setIsMutating(true);
    setCrudLoader(true);
    window.axiosIns
      .post(`products`, fd)
      .then(() => {
        setCrudLoader(false);
        setAddModal(false);
        enqueueSnackbar("Variant Added successfully.");
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add Variant. Try again."
          );
        }
        setCrudLoader(false);
        setIsMutating(false);
      })
      .then(() => {
        getDeviceType();
        setModulesSelected([]);
      });
  };

  const handleEdit = ({
    product_name,
    product_id,
    color,
    size,
    flavor,
    price,
    cost,
    product_description,
    product_picture,
    package_type,
    shelf_life,
    heating_time,
    status,
    product_type,
    concentration,
    barcode_of_product,
    ingredients_img,
    more_info_img,
    product_sku,
    long_description,
    images,
    weight,
    operator,
    age_restriction,
    is_recommended,
    product_tax
  }) => {

    const fd = new FormData();
    if (images && images[0]?.type && images.length) {
      for (let i = 0; i < images.length; i++) {
        fd.append(`img${i + 1}`, images[i])
      }
    }
    product_picture && product_picture.type && fd.append("product_picture", product_picture);
    barcode_of_product && barcode_of_product.type && fd.append("barcode_of_product", barcode_of_product);
    ingredients_img &&
      ingredients_img.type &&
      fd.append("ingredients_img", ingredients_img);
    more_info_img &&
      more_info_img.type &&
      fd.append("more_info_img", more_info_img);
    fd.append("product_sku", product_sku ?? "");
    fd.append("operator", operator ?? "");
    fd.append("long_description", long_description ?? "");
    fd.append("product_name", product_name);
    fd.append("product_id", product_id);
    fd.append("package_type", package_type ?? "");
    fd.append("shelf_life", shelf_life ?? "");
    fd.append("heating_time", heating_time ?? "");
    fd.append("status", status ?? "Enable");
    fd.append("color", color ?? "");
    fd.append("size", size ?? "");
    fd.append("flavor", flavor ?? "");
    fd.append("price", parseFloat(price).toFixed(2));
    fd.append("cost", parseFloat(cost).toFixed(2));
    fd.append("product_description", product_description ?? "");
    fd.append("product_type ", product_type ?? "");
    fd.append("concentration ", concentration ?? "");
    fd.append("weight", weight ?? "");
    fd.append("variant_type", "variant");
    fd.append("age_restriction", age_restriction ?? '0');
    fd.append("is_recommended", is_recommended ?? false);
    fd.append("product_tax", product_tax ?? '0.0000');
    fd.append("no_sale_days", '[]');


    const moduleId = modulesSelected[0].id;
    setIsMutating(true);
    setCrudLoader(true);
    window.axiosIns
      .patch(`products/${moduleId}`, fd)
      .then(() => {
        setCrudLoader(false);
        setEditModal(false);
        enqueueSnackbar("Variant edited successfully.");
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      }).catch(err => {
        if (err.detail) {
          enqueueSnackbar(err.detail)
        }
        else if (((err.response || {}).data || {}).detail) {
          enqueueSnackbar(((err.response || {}).data || {}).detail)
        }
        else {
          handleServerErrors(err, enqueueSnackbar, "Could not edit product. Try again.");
        }
        setCrudLoader(false);
        setEditModal(false);
        setIsMutating(false);
      }).then(() => {
        setDeviceList([])
        setModulesSelected([]);
        getDeviceType();
      })
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("/products", {
          params: {
            ...searchFilter,
            limit: rowsPerPage,
            // variant_type: "default",
            variant: 'variants',
            ordering,
          }
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = async (url) => {
    setup();
    const { data } = await window.axiosIns(url);
    handleRes(data);
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels,filterable,value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("/products", {
        params: {
          ...searchFilter,
          limit: rowsPerPage,
          variant: 'variants',
          state: 'all',
          ordering,
        }
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const variantExport = () => {
    let params = {
      ...searchQuery,
      variant: "true",
      state: "all",
      tz: getTimeZoneDifference(),
    };

    let product_ids = modulesSelected.map((value) => value?.id);
    if (product_ids.length > 0) params["id"] = product_ids.join(",");

    window.axiosIns("/products/export?variant=true", {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
      params: params
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Product-Variants-Summary-${moment().format("MM-DD-YYYY")}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  }

  const handleDelete = () => {
    modulesSelected.forEach((val, index) => {
      window.axiosIns
        .delete(`products/${val.id}`)
        .then(() => {
          setDeleteModal(false);
          if (modulesSelected.length === index + 1) {
            if (deviceTypeList.length - modulesSelected.length === 0 && page > 0) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getDeviceType();
            }
            enqueueSnackbar("Variant deleted successfully.");
          }
        }).catch(err => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          }
          else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail)
          }
          else {
            handleServerErrors(err, enqueueSnackbar, "Could not delete device. Try again.");
          }
        })
    });
  };

  const handleClick = (event) => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('template', fileUploaded);

    setIsMutating(true);
    window.axiosIns
      .post(`/products/import?variant=true`, formData)
      .then((resp) => {
        const {data: {data}} = resp;
        const errors = [];
        if (data.records_failed > 0) {
          Object.keys(data.failed_entries).forEach((key) => {
            errors.push(`Row ${key} : ${data.failed_entries[key]}`)
          })

          setImportError(errors);
          setIsInfo(true);
        }
        enqueueSnackbar(resp?.data?.message || "Variants Added successfully.", { autoHideDuration: 3000 });
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add variant. Try again."
          );
        }
        setIsMutating(false);
      })
      .then(() => {
        getDeviceType();
        setModulesSelected([]);
      });

  };
  const hiddenFileInput = React.useRef(null);

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title="Variants"
        description="
        All Variant are listed here. Variant can be used to associate a product to a particular Variant. This can be used to calculate Variant wise sales and operative statistics. "
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
            disabled={current_user.type === 'SU'}
          />
          <EditButton
            disabled={modulesSelected.length !== 1 || current_user.type === 'SU'}
            className="mr-3"
            label="Edit"
            onClick={() => setEditModal(true)}
          />
          <DeleteButton
            disabled={modulesSelected.length === 0 || current_user.type === 'SU'}
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
          {current_user.type !== "SU" && (
          <>
            <ExportButton
              className="mr-3"
              label="Export"
              onClick={() => variantExport()}
            />
            <ImportButton
              className="mr-3"
              label="Import"
              onClick={handleClick}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </>
          )}
        </div>
        <div className="d-flex">
          <SearchBox
            placeholder="Search"
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getDeviceType(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getDeviceType(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
              setModulesSelected(modulesSelected)
            }
        />
        <CrudDialog
          title="Add Variant"
          okText="Add Variant"
          fields={addfields}
          submitButtonDisabled={isMutating}
          crudLoader={crudLoader}
          description="Please fill in the details below."
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => setAddModal(false)}
        />
        <CrudDialog
          title="Edit Variant"
          okText="Save"
          description="Please edit the details below."
          fields={addfields}
          submitButtonDisabled={isMutating}
          values={{...modulesSelected[0],
            cost : currencyFormatter(modulesSelected[0]?.cost),
            price : currencyFormatter(modulesSelected[0]?.price),
            product_tax: taxFormatter(modulesSelected[0]?.product_tax)}}
          crudLoader={crudLoader}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => setEditModal(false)}
        />
        <CrudDialog
          title="Delete Variant"
          description="Are you sure you want to delete the Variant?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
        <InfoModal 
          title="Variant Import Errors"
          data={importError || []}
          open={isInfo}
          close={() => setIsInfo(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(Variant);
